.pswp {
	z-index: 999999;
}
.pswp__caption__center {
	text-align: center;
}
.pswp__img--placeholder--blank {
	background: none;
}
.pswp img {
	max-width: none;
	object-fit: contain;
}
