.active-link {
	font-weight: bold;
}

.fixed-toggle-helper {
	display: none;
}
@media screen and (max-width: 700px),
	   screen and (max-width: 896px) and (max-height: 600px) and (orientation:landscape) {
	.fixed-toggle-helper {
		display: block;
	}
}
