.printModal {
  text-align: center;
  color: #0460b5;
  background-color: #ffffffe8;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 300;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.printClose {
  position: absolute;
  top: 10px;
  right: 10px;
}

.printClose:before {
  content: "×";
  text-indent: 1px;
  text-align: center;
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  padding-top: .5em;
  font-family: Helvetica Neue, sans-serif;
  font-size: 2em;
  font-weight: 100;
  line-height: 1px;
  display: block;
  overflow: hidden;
}

.printSpinner {
  border: 2px solid #0460b5;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-top: 3px;
  margin-left: -40px;
  animation: .75s linear infinite spin;
  display: inline-block;
  position: absolute;
}

.printSpinner:after, .printSpinner:before {
  content: "";
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  display: none;
  position: absolute;
  top: -2px;
  left: -2px;
}

.printSpinner, .printSpinner:after, .printSpinner:before {
  border-color: #0460b5 #0000 #0000;
  animation-duration: 1.2s;
  display: inline-block;
}

.printSpinner:before {
  transform: rotate(120deg);
}

.printSpinner:after {
  transform: rotate(240deg);
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.slick-slider.other-lounges {
  margin-left: -12px;
}

.slick-slider.other-lounges .slick-track {
  margin: 0;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.pswp {
  z-index: 999999;
}

.pswp__caption__center {
  text-align: center;
}

.pswp__img--placeholder--blank {
  background: none;
}

.pswp img {
  object-fit: contain;
  max-width: none;
}

.slick-slider.booking-review {
  height: 196px;
  overflow: hidden;
}

.slick-slider.booking-review .slick-arrow {
  opacity: 0;
  display: flex !important;
}

.slick-slider.booking-review:hover .slick-arrow {
  opacity: 1;
}

@media only screen and (max-width: 958px) {
  .slick-slider.booking-review {
    height: 250px;
  }

  .slick-slider.booking-review .slick-arrow {
    opacity: 1;
  }
}

.slick-slider.booking-review .slick-dots {
  width: 100%;
  position: absolute;
  bottom: 16px;
}

.slick-slider.booking-review .slick-dots li {
  padding: 4px;
}

.slick-slider.booking-review .slick-dots li span {
  opacity: .5;
  background-color: #fff;
  border: 1px solid #0d10141a;
  width: 8px;
  height: 8px;
}

.slick-slider.booking-review .slick-dots li.slick-active span {
  opacity: 1;
}

#outdated {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  z-index: 1500;
  background-color: #f25648;
  width: 100%;
  padding: 0 24px 24px 0;
  font-family: Open Sans, Segoe UI, sans-serif;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#outdated.fullscreen {
  height: 100%;
}

#outdated .vertical-center {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

#outdated h6 {
  margin: 12px 0;
  font-size: 25px;
  line-height: 25px;
}

#outdated p {
  margin: 0;
  font-size: 12px;
  line-height: 12px;
}

#outdated #buttonUpdateBrowser {
  color: #fff;
  cursor: pointer;
  border: 2px solid #fff;
  width: 230px;
  margin: 30px auto 0;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  position: relative;
}

#outdated #buttonUpdateBrowser:hover {
  color: #f25648;
  background-color: #fff;
}

#outdated .last {
  width: auto;
  height: 20px;
  display: inline-table;
  position: absolute;
  top: 10px;
  right: 70px;
}

#outdated .last[dir="rtl"] {
  left: 25px !important;
  right: auto !important;
}

#outdated #buttonCloseUpdateBrowser {
  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 36px;
  line-height: 36px;
  text-decoration: none;
  display: block;
  position: relative;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -o-transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity= 40);
  z-index: 2;
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 32px;
  transition: all .25s ease-in;
  position: absolute;
  top: 20px;
}

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  content: "";
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  margin: 0 5px;
  display: inline-block;
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity= 0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel-root {
  outline: none;
}

.carousel {
  width: 100%;
  position: relative;
}

.carousel * {
  box-sizing: border-box;
}

.carousel img {
  pointer-events: none;
  width: 100%;
  display: inline-block;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  outline: 0;
  margin-top: -13px;
  font-size: 18px;
  top: 50%;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}

.carousel .thumbs {
  -o-transition: all .15s ease-in;
  white-space: nowrap;
  list-style: none;
  transition: all .15s ease-in;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.carousel .thumb {
  -o-transition: border .15s ease-in;
  white-space: nowrap;
  border: 3px solid #fff;
  margin-right: 6px;
  padding: 2px;
  transition: border .15s ease-in;
  display: inline-block;
  overflow: hidden;
}

.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #333;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  color: #fff;
  margin-top: 0;
  padding: 5px;
  font-size: 26px;
  top: 0;
  bottom: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #0003;
}

.carousel .slider-wrapper {
  -o-transition: height .15s ease-in;
  width: 100%;
  margin: auto;
  transition: height .15s ease-in;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.carousel .slider.animated {
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.carousel .slide {
  text-align: center;
  min-width: 100%;
  margin: 0;
  position: relative;
}

.carousel .slide img {
  vertical-align: top;
  border: 0;
  width: 100%;
}

.carousel .slide iframe {
  border: 0;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  display: inline-block;
}

.carousel .slide .legend {
  -o-transition: all .5s ease-in-out;
  color: #fff;
  text-align: center;
  opacity: .25;
  transition: all .5s ease-in-out;
  -o-transition: opacity .35s ease-in-out;
  background: #000;
  border-radius: 10px;
  width: 90%;
  margin-left: -45%;
  padding: 10px;
  font-size: 12px;
  transition: opacity .35s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

.carousel .control-dots {
  text-align: center;
  z-index: 1;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  position: absolute;
  bottom: 0;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}

.carousel .control-dots .dot {
  -o-transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity= 30);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  transition: opacity .25s ease-in;
  display: inline-block;
  box-shadow: 1px 1px 2px #000000e6;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .carousel-status {
  text-shadow: 1px 1px 1px #000000e6;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.carousel:hover .slide .legend {
  opacity: 1;
}

.carousel .control-dots {
  margin: 0;
}

a:focus {
  outline-offset: 1px;
}

.ReactCollapse--collapse {
  transition: height .5s;
}

a:hover svg.dim {
  opacity: .5;
}

.active-link {
  font-weight: bold;
}

.fixed-toggle-helper {
  display: none;
}

@media screen and (max-width: 700px), screen and (max-width: 896px) and (max-height: 600px) and (orientation: landscape) {
  .fixed-toggle-helper {
    display: block;
  }
}

/*# sourceMappingURL=client.css.map */
