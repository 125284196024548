.slick-slider.booking-review {
	height: 196px;
	overflow: hidden;
}
.slick-slider.booking-review .slick-arrow {
	display: flex !important;
	opacity: 0;
}
.slick-slider.booking-review:hover .slick-arrow {
	opacity: 1;
}
@media only screen and (max-width: 958px) {
	.slick-slider.booking-review {
		height: 250px;
	}
	.slick-slider.booking-review .slick-arrow {
		opacity: 1;
	}
}
.slick-slider.booking-review .slick-dots {
	width: 100%;
	position: absolute;
	bottom: 16px;
}
.slick-slider.booking-review .slick-dots li {
	padding: 4px;
}
.slick-slider.booking-review .slick-dots li span {
	width: 8px;
	height: 8px;
	background-color: #FFFFFF;
	border: 1px solid rgba(13,16,20,0.10);
	opacity: 0.5;
}
.slick-slider.booking-review .slick-dots li.slick-active span {
	opacity: 1;
}
