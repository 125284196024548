a:focus {
	outline-offset: 1px;
}

.ReactCollapse--collapse {
	transition: height 500ms;
}

a:hover svg.dim {
	opacity: 0.5;
}